import * as types from './types';

const initState = {
  status: 'IDLE',
  roles: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function rolesReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_ROLE_REQUEST:
      return { ...state, status: 'LOADING' };
    case types.FETCH_ROLE_SUCCEEDED:
      return { ...state, status: 'SUCCEEDED', ...payload };
    case types.FETCH_ROLE_FAILED:
      return { ...state, status: 'FAILED' };
    default:
      return state;
  }
}
