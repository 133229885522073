import React, { useEffect, useState } from 'react';

import LoadingSpinner from 'components/library/LoadingSpinner';
import { getUserByEmail } from 'services/clients/api/kyc';
import * as statuses from 'services/store/states';
import { getProjectById } from 'services/clients/api/project';
import { getUserFilesByIss } from 'services/clients/api/media_files';
import { isEmail } from 'utils/email';
import getErrorMessage from 'utils/error';

import SearchResultsError from '../SearchResultsError';
import SearchResultsEmpty from '../SearchResultsEmpty';
import SearchResultsUserEmpty from '../SearchResultsUserEmpty';
import SearchResultsList from '../SearchResultsList';

interface Props {
  search: string,
}

export default function SearchResultsManager({ search }: Props) {
  const [iss, setIss] = useState(0);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(statuses.IDLE);
  const [error, setError] = useState('');

  useEffect(() => {
    setStatus(statuses.LOADING);
    const getUserIss = async () => {
      try {
        if (isEmail(search)) {
          const { data } = await getUserByEmail(search);
          if (data.length > 0) {
            setIss((data[0].iss) || 0);
            setStatus(statuses.SUCCESS);
          } else {
            setStatus(statuses.FAIL);
            setError('user_not_found');
          }
        } else {
          const { data } = await getProjectById(search);
          setIss(data.entity.user.iss);
        }
      } catch (err) {
        setIss(0);
        setStatus(statuses.FAIL);
        if ((err)?.response?.status === 404) {
          setError('user_not_found');
        } else {
          setError(getErrorMessage(err));
        }
      }
    };
    getUserIss();
  }, [search]);

  useEffect(() => {
    const getUserFiles = async () => {
      try {
        const { data } = await getUserFilesByIss(iss);
        setFiles(data);
        setStatus(statuses.SUCCESS);
      } catch (err) {
        setStatus(statuses.FAIL);
        setError(getErrorMessage(err));
      }
    };

    if (iss && iss !== 0) {
      getUserFiles();
    }
  }, [iss]);

  if (status === statuses.LOADING) {
    return (<LoadingSpinner />);
  }

  if (status === statuses.FAIL) {
    if (error === 'user_not_found') {
      return (<SearchResultsUserEmpty />);
    }
    return (<SearchResultsError error={error} />);
  }

  if ((status === statuses.SUCCESS) && (files.length === 0)) {
    return (<SearchResultsEmpty />);
  }

  return (<SearchResultsList files={files} />);
}
