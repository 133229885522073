import React from 'react';

import styles from './Navigation.module.css';

interface Props {
  children?: React.ReactElement,
}

export default function Navigation({ children }: Props) {
  return (
    <nav className={styles.navigation}>
      <p className={styles['logo-container']}>
        <span className={styles.logo}>
          Ayomi
        </span>
        <span className={styles.dot} />
        <span className={styles.application}>
          Drive
        </span>
      </p>
      { children }
    </nav>
  );
}

Navigation.defaultProps = {
  children: undefined,
};
