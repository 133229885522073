import React from 'react';
import RouteConfig from './services/routes/routes';

import './components/library/style/normalize.css';
import './components/library/style/reset.css';
import './components/library/style/typography.css';
import './components/library/style/animation.css';

function App() {
  return (
    <div className="App">
      <RouteConfig />
    </div>
  );
}

export default App;
