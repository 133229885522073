import axios from 'axios';
import axiosRetry from 'axios-retry';
import domains from '../../global';
import getCookie from '../../utils/getCookie';

axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken');

const axiosMockClient = axios.create({
  baseURL: domains.url,
  timeout: 120000,
});

export const axiosAuthClient = axios.create({
  baseURL: `${domains.auth}/api`,
  timeout: 60000,
});

axiosMockClient.interceptors.request.use(
  async (config) => {
    const accessToken = window.sessionStorage.getItem('auth_token');
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      };
    }
    // eslint-disable-next-line consistent-return
    return config;
  },
  (error) => Promise.reject(error),
);

axiosMockClient.interceptors.response.use(async (axiosResponse) => {
  const originalRequest = axiosResponse.config;
  /* Token invalid */
  // eslint-disable-next-line no-underscore-dangle
  if (axiosResponse.data.status === 'KO' && axiosResponse.data.code === 401 && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    const refreshToken = window.localStorage.getItem('auth_refresh');
    /* Get new token by using the refresh token */
    if (refreshToken) {
      try {
        const { data } = await axiosAuthClient.post('/auth/refresh_token', { refresh_token: refreshToken });
        window.sessionStorage.setItem('auth_token', data.data.token);
        window.localStorage.setItem('auth_refresh', data.data.refresh_token);
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        return axiosMockClient(originalRequest);
      } catch (err) {
        document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
      }
      return Promise.reject(new Error(axiosResponse.data.msg));
    }
    /* no refresh token, user need to log in */
    document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
  }
  return axiosResponse;
});

axiosRetry(axiosMockClient, { retries: 3 });

export default axiosMockClient;
