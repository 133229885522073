import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Navigation from 'components/Navigation';
import Searchbar from 'components/Searchbar';
import SearchResultsManager from './SearchResultsManager';

import styles from './SearchResults.module.css';

export default function SearchResults() {
  const { search } = useParams();
  const history = useHistory();
  const [query, setQuery] = useState(search || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => history.push(`/search/${query}`);

  return (
    <section className={styles.section}>
      <Navigation>
        <Searchbar query={query} onChange={handleChange} onSearch={handleSearch} />
      </Navigation>
      <main>
        {
          (search)
          && (
            <SearchResultsManager search={search} />
          )
        }
      </main>
    </section>
  );
}
