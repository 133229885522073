/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, useHistory,
} from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import MediaViewer from '../../views/MediaViewer';
import MediaAdmin from '../../views/MediaAdmin';
import Loading from '../../components/library/Loading/index';
import verifyToken from '../store/features/roles/actions';
import { setVirtualRoutes } from '../store/features/media/actions';
import Search from '../../views/Search';
import SearchResults from '../../views/SearchResults';
import SearchUnauthorized from '../../views/SearchUnauthorized';

const Routes = [
  {
    path: '/search/:search',
    auth: true,
    roles: ['MEDIA_MANAGER'],
    authorizedRole: ['MEDIA_MANAGER'],
    routes: [],
    component: SearchResults,
  },
  {
    path: '/unauthorized',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: SearchUnauthorized,
  },
  {
    path: '/media/public',
    component: MediaViewer,
    auth: false,
  },
  {
    path: '/media',
    component: MediaViewer,
    auth: true,
  },
  {
    path: '/admin',
    component: MediaAdmin,
    auth: true,
    roles: ['MEDIA_ADMIN'],
  },
  {
    path: '/',
    auth: true,
    roles: ['MEDIA_MANAGER'],
    authorizedRole: ['MEDIA_MANAGER'],
    routes: [],
    component: Search,
  },
];

function RouteWithSubRoutes({
  // eslint-disable-next-line react/prop-types
  path, auth, routes, status, roles, authorizedRole, ...route
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth) {
      dispatch(verifyToken());
    }
    if (path === '/media' || path === '/media/public') {
      const url = window.location.pathname;
      dispatch(setVirtualRoutes(url));
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (roles && roles.length > 0 && status === 'SUCCEEDED') {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        // eslint-disable-next-line react/prop-types
        if (!authorizedRole.includes(role)) {
          if (history.length === 1) {
            document.location.assign('https//auth.ayomi.fr/');
          } else {
            document.location.assign(`${window.location.origin}/unauthorized`);
          }
        }
      }
    }
  }, [status]);

  if ((auth && status === 'SUCCEEDED') || !auth) {
    return (
      <>
        <Route
          path={path}
          render={(props) => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={routes} />
          )}
        />
      </>
    );
  }
  return <Loading />;
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedRouteWithSubRoutes = connect(mapStateToProps)(RouteWithSubRoutes);

export default function RouteConfig() {
  return (
    <Router>
      <div className="main">
        <Switch>
          {Routes.map((route) => (
            <ConnectedRouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
      </div>
    </Router>
  );
}
