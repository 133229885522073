import jwt from 'jsonwebtoken';
import { axiosAuthClient as axios } from '../../../clients/axiosClient';
import * as types from './types';
import domains from '../../../../global';

function verifyToken() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_ROLE_REQUEST });
    try {
      const { data } = await axios.get('/auth/cert');
      const { cert } = data.data;
      const token = window.sessionStorage.getItem('auth_token');
      jwt.verify(token, cert, async (err, decoded) => {
        if (!err) {
          dispatch({
            type: types.FETCH_ROLE_SUCCEEDED,
            payload: { roles: decoded.roles, email: decoded.email },
          });
        } else {
          const params = new URLSearchParams(window.location.search);
          let refreshToken = params.get('refresh_token');
          if (!refreshToken) {
            refreshToken = window.localStorage.getItem('auth_refresh');
          }
          if (refreshToken) {
            const { data: dataRefresh } = await axios.post('/auth/refresh_token', { refresh_token: refreshToken });
            if (dataRefresh.status === 'OK') {
              window.sessionStorage.setItem('auth_token', dataRefresh.data.token);
              window.localStorage.setItem('auth_refresh', dataRefresh.data.refresh_token);
              dispatch(verifyToken());
            } else {
              document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
            }
          } else {
            document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
          }
        }
      });
    } catch (err) {
      dispatch({ type: types.FETCH_ROLE_FAILED });
    }
  };
}

export function isUserAuthorized(usersRoles, necessaryRoles) {
  return necessaryRoles.every((r) => usersRoles.includes(r));
}

export default verifyToken;
