import React from 'react';
import styles from './MediaViewer.module.css';
import MediaViewerLoader from './MediaViewerLoader';
import MediaViewerManager from './MediaViewerManager';

export default function MediaViewer() {
  return (
    <section className={styles.container}>
      <MediaViewerLoader>
        {
          ({
            status,
            fileResult,
            downloadURL,
            type,
          }) => (
            <MediaViewerManager
              status={status}
              fileResult={fileResult}
              downloadURL={downloadURL}
              type={type}
            />
          )
}
      </MediaViewerLoader>
    </section>
  );
}
