import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetApp } from '@material-ui/icons';
import styles from './MediaViewerUnavailable.module.css';

// eslint-disable-next-line react/prop-types
export default function MediaViewerUnavailable({ filename, downloadURL }) {
  const { t } = useTranslation();
  return (
    <section className={styles.media}>
      <nav className={styles.navigation}>
        <p>{ filename }</p>
        <a
          href={downloadURL}
          download={filename}
          className={styles.download}
        >
          <GetApp />
        </a>
      </nav>
      <main className={styles.content}>
        <div className={styles.modal}>
          <h1 className={styles.title}>
            {(t('viewer.error.PREVIEW_UNAVAILABLE.title'))}
          </h1>
          <p>
            {(t('viewer.error.PREVIEW_UNAVAILABLE.description'))}
          </p>
          <a
            className={styles.button}
            href={downloadURL}
            download={filename}
          >
            <GetApp className={styles.icon} />
            {t('viewer.download')}
          </a>
        </div>
      </main>
    </section>
  );
}
