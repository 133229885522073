import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchMedia, fetchPublicMedia } from '../../../services/store/features/media/actions';
import MediaViewerLoader from './MediaViewerLoader';

const mapStateToProps = (state) => ({
  ...state.media,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchMedia, fetchPublicMedia }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaViewerLoader);
