import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Media } from 'types/media';

import styles from './SearchResultsList.module.css';
import SearchResultsDocument from '../SearchResultsDocument';

interface Props {
  files: Media[],
}

export default function SearchResultsList({ files }: Props) {
  const [sort, setSort] = useState<'asc' | 'dsc'>('asc');
  const { t } = useTranslation();

  const getSortedFiles = () => {
    const newFiles = files;
    newFiles.sort((a, b) => {
      const pathA = a.virtual_path.split('/').pop() || '';
      const pathB = b.virtual_path.split('/').pop() || '';
      if (pathA < pathB) return (sort === 'asc') ? -1 : 1;
      if (pathA > pathB) return (sort === 'asc') ? 1 : -1;
      return 0;
    });
    return newFiles;
  };

  const handleSort = () => setSort((curr) => ((curr === 'asc') ? 'dsc' : 'asc'));

  return (
    <div className={styles.results}>
      <div className={styles.header}>
        <div className={`${styles.grid} ${styles.title}`}>
          <div className={styles.group}>
            <p>
              {t('results.file_name')}
            </p>
            <button
              type="button"
              onClick={handleSort}
            >
              <span className="material-icons">
                {
                  (sort === 'asc') ? 'arrow_downward' : 'arrow_upward'
                }
              </span>
            </button>
          </div>
          <p className={styles.right}>
            {t('results.version')}
          </p>
          <p className={styles.right}>
            {t('results.date')}
          </p>
        </div>
      </div>
      <div className={styles.main}>
        {
            (sort ? getSortedFiles() : files).map((file) => (
              <SearchResultsDocument
                key={`${file.virtual_path}-${file.version}-${file.date}`}
                file={file}
              />
            ))
        }
      </div>
    </div>
  );
}
