import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SearchResultsError.module.css';

interface Props {
  error: string,
}

export default function SearchResultsError({ error }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.state}>
      <div className={styles.icons}>
        <span className="material-icons">
          block
        </span>
      </div>
      <p className={styles.title}>
        {t('search.server_error')}
      </p>
      <p className={styles.description}>
        { error }
      </p>
    </div>
  );
}
