import React from 'react';
import { useTranslation } from 'react-i18next';

import status from '../SearchResults/SearchResultsError/SearchResultsError.module.css';
import styles from './SearchUnauthorized.module.css';

export default function SearchUnauthorized() {
  const { t } = useTranslation();
  return (
    <section className={styles.unauthorized}>
      <div className={status.icons}>
        <span className="material-icons">
          block
        </span>
      </div>
      <p className={status.title}>
        {t('search.unauthorized')}
      </p>
      <p className={status.description}>
        {t('search.unauthorized_description')}
      </p>
    </section>
  );
}
