import React from 'react';
import styles from '../MediaViewer.module.css';

export default function MediaViewerPDF({
  // eslint-disable-next-line react/prop-types
  type, downloadURL, filename,
}) {
  return (
    <object
      className={styles.embeded}
      type={type}
      data={downloadURL}
      aria-label={filename}
    />
  );
}
