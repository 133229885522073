import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './MediaViewerError.module.css';

// eslint-disable-next-line react/prop-types
export default function MediaViewerError({ type }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>
        { t(`viewer.error.${type}.title`) }
      </h1>
      <p>
        { t(`viewer.error.${type}.description`) }
      </p>
    </div>
  );
}
