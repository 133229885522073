/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';

import * as statuses from '../../../services/store/states';

const VIEW_STATE = {
  loading: 'LOADING', // Servor promise pending
  preview: 'PREVIEW', // File get from the servor and mimeType supported,
  noPreview: 'PREVIEW_UNAVAILABLE', // File get from the servor and mimeType not supported
  unauthorized: 'UNAUTHORIZED', // Request failed with error 401
  noFile: 'FILE_NOT_FOUND', // Request failed with error 404
  error: 'ERROR', // Request failed with other error code
};

export default function MediaViewerLoader({
  status, file, virtualRoute, actions, error, children,
}) {
  const [viewState, setViewState] = useState(VIEW_STATE.loading);
  const [fileResult, setFileResult] = useState(null);
  const [downloadURL, setDownloadUrl] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (virtualRoute && !virtualRoute.includes('public') && status === statuses.IDLE) {
      actions.fetchMedia();
    } else if (virtualRoute && virtualRoute.includes('public') && status === statuses.IDLE) {
      actions.fetchPublicMedia();
    }
  }, [virtualRoute]);

  useEffect(() => {
    if (status === statuses.SUCCESS) {
      if (file) {
        setType(file.type);
        setDownloadUrl(window.URL.createObjectURL(file));
        const reader = new FileReader();
        if (type && type.split('/')[0] !== 'text') {
          reader.readAsDataURL(file);
        } else {
          reader.readAsText(file);
        }
        reader.onload = () => {
          const supportedType = ['image', 'application/pdf'];
          const rawLog = reader.result;
          setFileResult(rawLog);
          if (supportedType.includes(file.type) || supportedType.includes(file.type?.split('/')[0])) {
            setViewState(VIEW_STATE.preview);
          } else {
            setViewState(VIEW_STATE.noPreview);
          }
        };
      }
    }
    if (status === statuses.FAIL) {
      if (error.response.status === 404) {
        setViewState(VIEW_STATE.noFile);
      }
      if (error.response.status === 401) {
        setViewState(VIEW_STATE.unauthorized);
      } else {
        setViewState(VIEW_STATE.error);
      }
    }
  }, [status]);
  return (
    <>
      {
        children({
          status: viewState, fileResult, downloadURL, type,
        })
      }
    </>
  );
}
