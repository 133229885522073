import * as types from './types';
import { getMedia, getMediaDetails } from '../../../clients/api/media';

export function setVirtualRoutes(url) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_VIRTUAL_ROUTES,
      payload: url,
    });
  };
}

export function fetchPublicMedia() {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MEDIA_REQUEST,
    });
    const url = getState().media.virtualRoute;
    try {
      const response = await getMedia(url);
      dispatch({
        type: types.FETCH_MEDIA_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_MEDIA_FAILED,
        payload: err,
      });
    }
  };
}

export function fetchMedia() {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MEDIA_REQUEST,
    });
    const url = getState().media.virtualRoute;
    try {
      const MAX_BLOB_SIZE = 500000000;
      const { data } = await getMediaDetails(url);
      if (data.status === 'OK') {
        dispatch({
          type: types.SET_MEDIA_DETAILS,
          payload: data.data,
        });
        if (data.data.size < MAX_BLOB_SIZE) {
          const response = await getMedia(url);
          dispatch({
            type: types.FETCH_MEDIA_SUCCESS,
            payload: response.data,
          });
        } else {
          dispatch({
            type: types.FETCH_MEDIA_UNSUPPORTED,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_MEDIA_FAILED,
        payload: err,
      });
    }
  };
}
