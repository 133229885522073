import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/library/Button';
import Navigation from 'components/Navigation';
import Searchbar from 'components/Searchbar';

import styles from './Search.module.css';

export default function Search() {
  const [query, setQuery] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const search = () => {
    history.push(`/search/${query}`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <section className={styles.section}>
      <Navigation />
      <main className={styles.main}>
        <p className={styles['logo-container']}>
          <span className={styles.logo}>
            Ayomi
          </span>
          <span className={styles.dot} />
          <span className={styles.application}>
            Drive
          </span>
        </p>
        <Searchbar
          query={query}
          onChange={handleChange}
          className={styles.searchbar}
        />
        <Button
          label={t('search.access_documents')}
          onButtonClick={search}
          disabled={query.trim() === ''}
        />
      </main>
    </section>
  );
}
