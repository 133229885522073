import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MediaViewer from './MediaViewer';
import { fetchMedia } from '../../services/store/features/media/actions';

const mapStateToProps = (state) => ({
  ...state.media,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchMedia }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaViewer);
