import { combineReducers } from 'redux';

import rolesReducer from './features/roles/reducer';
import mediaReducer from './features/media/reducer';

const rootReducer = combineReducers({
  media: mediaReducer,
  roles: rolesReducer,
});

export default rootReducer;
