import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as statuses from 'services/store/states';
import { Media } from 'types/media';
import LoadingSpinner from 'components/library/LoadingSpinner';
import { getFile } from 'services/clients/api/media_files';
import getErrorMessage from 'utils/error';

import styles from './DocumentPreview.module.css';

interface Props {
  media: Media,
  onClose: () => void,
}

const imgArray = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];

export default function DocumentPreview({ media, onClose }: Props) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [file, setFile] = useState<string | undefined>();
  const [error, setError] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const { data } = await getFile(media.virtual_path);
        setFile(window.URL.createObjectURL(data));
        setStatus(statuses.SUCCESS);
      } catch (err) {
        setError(getErrorMessage(err));
        setStatus(statuses.FAIL);
      }
    };
    fetchMedia();
  }, [media]);

  const onDownload = () => {
    if (file) {
      const link = document.createElement('a');
      link.href = file;
      link.download = media.virtual_path.split('/').pop() || 'file';
      link.click();
      document.removeChild(link);
    }
  };

  return (
    <section className={styles.preview}>
      <header className={styles.header}>
        <div className={styles.row}>
          <button
            type="button"
            onClick={onClose}
          >
            <span className="material-icons">
              arrow_back
            </span>
          </button>
          <p>
            {media.virtual_path.split('/').pop()}
          </p>
        </div>
        <button
          type="button"
          onClick={onDownload}
        >
          <span className="material-icons">
            download
          </span>
        </button>
      </header>
      <main className={styles.main}>
        { (status === statuses.LOADING) && <LoadingSpinner /> }
        {
          (status === statuses.SUCCESS)
          && (
            <>
              {
                (imgArray.includes(media.type))
                && (
                  <img
                    src={file}
                    alt={media.virtual_path}
                    className={styles.image}
                  />
                )
              }
              {
                (media.type === 'pdf')
                && (
                  <object
                    type="application/pdf"
                    data={file}
                    className={styles.pdf}
                  >
                    <p>Fallback</p>
                  </object>
                )
              }
              {
                (!imgArray.includes(media.type) && !(media.type === 'pdf'))
                && (
                  <div className={styles.error}>
                    <div className={styles.icon}>
                      <span className="material-icons">
                        image_not_supported
                      </span>
                    </div>
                    <p className={styles.title}>
                      {t('results.format_not_supported')}
                    </p>
                    <p className={styles.description}>
                      {t('results.format_not_supported_description')}
                    </p>
                  </div>
                )
              }
            </>
          )
        }
        {
          (status === statuses.FAIL)
          && (
            <div className={styles.error}>
              <div className={styles.icon}>
                <span className="material-icons">
                  block
                </span>
              </div>
              <p className={styles.title}>
                {t('search.server_error')}
              </p>
              <p className={styles.description}>{ error }</p>
            </div>
          )
        }
      </main>
    </section>
  );
}
