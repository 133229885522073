import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Searchbar.module.css';

interface Props {
  query: string,
  className?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onSearch?: () => void,
}

export default function Searchbar({
  query, className, onChange, onSearch,
}: Props) {
  const { t } = useTranslation();
  const input = useRef(null);

  const focusInput = () => {
    if (input.current) {
      (input.current as HTMLInputElement).focus();
    }
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSearch) onSearch();
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      className={`${styles['input-container']} ${className}`}
      onFocus={focusInput}
    >
      <span className={`material-icons ${styles.icon}`}>
        search
      </span>
      <input
        className={styles.input}
        placeholder={t('search.placeholder')}
        ref={input}
        value={query}
        onChange={onChange}
        onKeyDown={handleSearch}
      />
    </div>
  );
}

Searchbar.defaultProps = {
  className: undefined,
  onSearch: undefined,
};
