import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SearchResultsEmpty.module.css';
import state from '../SearchResultsError/SearchResultsError.module.css';

export default function SearchResultsEmpty() {
  const { t } = useTranslation();
  return (
    <div className={state.state}>
      <div className={styles.icons}>
        <span className="material-icons">
          search
        </span>
      </div>
      <p className={state.title}>
        {t('search.files_empty')}
      </p>
      <p className={state.description}>
        {t('search.files_empty_description')}
      </p>
    </div>
  );
}
