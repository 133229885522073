/* eslint react/prop-types: 0 */

import React from 'react';
import FileLoading from '../../../components/library/Loading';
import MediaViewerPDF from '../MediaViewerPDF';
import MediaViewerImage from '../MediaViewerImage';
import MediaViewerUnavailable from '../MediaViewerUnavailable';
import MediaViewerError from '../MediaViewerError';

export default function MediaViewerManager({
  status, type, fileResult, filename, downloadURL,
}) {
  const getViewer = (view) => {
    if (view === 'application/pdf') {
      return (
        <MediaViewerPDF
          type={type}
          fileResult={fileResult}
          filename={filename}
          downloadURL={downloadURL}
        />
      );
    }
    if (view.split('/')[0] === 'image') {
      return (
        <MediaViewerImage
          filename={filename}
          downloadURL={downloadURL}
          fileResult={fileResult}
        />
      );
    }
    return <MediaViewerUnavailable />;
  };

  return (
    <main>
      {
        (status === 'LOADING') && <FileLoading />
      }
      {
        (status === 'PREVIEW') && getViewer(type)
      }
      {
        (status === 'PREVIEW_UNAVAILABLE') && <MediaViewerUnavailable filename={filename} downloadURL={downloadURL} />
      }
      {
        (status === 'UNAUTHORIZED') && <MediaViewerError type="UNAUTHORIZED" />
      }
      {
        (status === 'FILE_NOT_FOUND') && <MediaViewerError type="FILE_NOT_FOUND" />
      }
      {
        (status === 'ERROR') && <MediaViewerError type="ERROR" />
      }
    </main>
  );
}
