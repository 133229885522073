import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  virtualRoute: '',
  status: 'IDLE',
  error: null,
  file: null,
  details: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function mediaReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_VIRTUAL_ROUTES:
      return { ...state, virtualRoute: payload, filename: payload.split('/').pop() };
    case types.FETCH_MEDIA_REQUEST:
      return { ...state, status: statuses.LOADING };
    case types.FETCH_MEDIA_SUCCESS:
      return { ...state, status: statuses.SUCCESS, file: payload };
    case types.FETCH_MEDIA_FAILED:
      return { ...state, status: statuses.FAIL, error: payload };
    case types.SET_MEDIA_DETAILS:
      return { ...state, details: payload };
    case types.FETCH_MEDIA_UNSUPPORTED:
      return { ...state, status: statuses.FAIL, error: 'unsupported' };
    default:
      return state;
  }
}

export default mediaReducer;
