import React, { useState } from 'react';

import { Media } from 'types/media';

import styles from '../SearchResultsList/SearchResultsList.module.css';
import DocumentPreview from './DocumentPreview';

interface Props {
  file: Media,
}

const imgArray = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];

export default function SearchResultsDocument({ file }: Props) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const getIcon = (type: string) => {
    if (imgArray.includes(type)) {
      return 'image';
    }
    if (type === 'pdf') return 'picture_as_pdf';
    return 'article';
  };

  const openPreview = () => setIsPreviewOpen(true);
  const openPreviewFromKeyboard = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') openPreview();
  };
  const closePreview = () => setIsPreviewOpen(false);

  return (
    <>
      {
        (isPreviewOpen)
        && (<DocumentPreview media={file} onClose={closePreview} />)
      }
      <div
        role="button"
        tabIndex={-1}
        className={`${styles.grid} ${styles.item}`}
        onClick={openPreview}
        onKeyDown={openPreviewFromKeyboard}
      >
        <p className={styles.document}>
          <span className={`material-icons ${styles.type}`}>{getIcon(file.type)}</span>
          <span>{file.virtual_path.split('/').pop()}</span>
        </p>
        <p className={styles.right}>{file.version}</p>
        <p className={styles.right}>{file.date}</p>
      </div>
    </>
  );
}
